import React from 'react';
import Img from "gatsby-image";

interface Props {
  image: any;
  alt: string;
}

function CustomImg(props: Props) {
  const { alt, image } = props;

  if (typeof image === 'string') {
    return <img src={image} alt={alt} />
  }

  return (
    <Img fluid={image} alt={alt} />
  );
}

export default CustomImg;
