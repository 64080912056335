import React, { ReactNode } from 'react';

interface HTMLContentProps {
  content: string;
  className?: string;
}

export const HTMLContent = ({ content, className }: HTMLContentProps) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

interface ContentProps {
  content: ReactNode;
  className: string;
}

export const Content = ({ content, className }: ContentProps) => (
  <div className={className}>{content}</div>
);
