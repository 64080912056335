import React from 'react';
import { graphql } from 'gatsby';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Content, HTMLContent } from "../components/Content";
import CustomImg from "../components/CustomImg";

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 672, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  .image-wrapper {
    max-width: 672px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

interface TemplateProps {
  image: any;
  body: string;
  contentComponent?: any;
}

export const AboutPageTemplate = ({
  image,
  body,
  contentComponent,
}: TemplateProps) => {
  const PageContent = contentComponent || Content;
  return (
    <Container maxWidth="md" className="overlay">
      <h1>About</h1>
      <StyledContent>
        <div className="image-wrapper">
          <CustomImg image={image} alt="About Image" />
        </div>
        <PageContent content={body} />
      </StyledContent>
    </Container>
  )
};

interface PageProps {
  data: {
    markdownRemark: {
      frontmatter: any;
    };
  };
}

const AboutPage = ({ data }: PageProps) => {
  const { frontmatter, html } = data.markdownRemark;
  const image = frontmatter.image ? frontmatter.image.childImageSharp.fluid : null;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        image={image}
        body={html}
      />
    </Layout>
  );
};

export default AboutPage;
